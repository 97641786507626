import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardActions, Chip, CircularProgress, Grid, Typography, makeStyles } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons';
import StyleSelectorBackground from 'component/_hh/StyleSelectorBackground';
import React from 'react'
import { parseAndFormatDateTime } from 'utils/date';
import { rgbaToHex } from 'views/Utils/helpers';
import QualityPreferencesIcon from '../../assets/images/team-preferences/star.svg';
import EaseOfUsePreferencesIcon from '../../assets/images/team-preferences/magicpen.svg';
import CommentsView from 'component/_hh/CommentsView';

const useStyles = makeStyles((theme) => ({
   heading: {
       fontSize: theme.typography.pxToRem(15),
       fontWeight: theme.typography.fontWeightRegular,
   },
   overlaymain: {
      position: 'relative',
      display: 'block',
      margin: '0px',
      '&:hover > span': {
          display: 'flex',
      },
  },
   tag: {
      marginRight: 3,
      marginTop: 3
  }
}));

function Field({ name, value, fullWidth = false, valueStyles = {}, bottomSpacing=10, labelWidth=6 }) {
   return (
       <Grid container spacing={3} style={{ borderBottom: "1px solid #efefef", marginBottom: bottomSpacing }}>
           <Grid item xs={12} sm={fullWidth ? 3 : labelWidth}>
               <Typography variant="h6" color="inherit">
                   {name}:
               </Typography>
           </Grid>
           <Grid item xs={12} sm={fullWidth ? 9 : (12 - labelWidth)}>
               <div style={valueStyles}> {value}</div>
           </Grid>
       </Grid>
   )
}

function FieldNormal({ name, value, fullWidth = false, valueStyles = {}, bottomSpacing=10, labelWidth=6 }) {
   return (
       <Grid container spacing={3} style={{ borderBottom: "1px solid #efefef", marginBottom: bottomSpacing }}>
           <Grid item xs={12} sm={12}>
               <Typography variant="h6" color="inherit">
                   {name}: <span style={{marginLeft: "5px"}}> {value}</span>
               </Typography>
           </Grid>
       </Grid>
   )
}

const  AssignView = ({stateData, selectedHero, setShowAssignEditorModal, setShowRequestResubmitModal, setShowHoldModal, updateHold, deleteRequest}) => {
   const classes = useStyles();
   const {imageMetadata, isLoading, showGuidelineImg,showGuideline, guidelineImg, addGuidelineImg, imageFileSize, hero, selectedRequest} = stateData;
   // console.log('selectedHero assign view', selectedHero, hero, selectedRequest)
   const getGuidelines = (guidelineStr) => {
      let guideline = <a href='https://www.dropbox.com/s/tz9cm2m0ucxajj2/Frontify%20Guidelines.png?raw=1'>https://www.dropbox.com/s/tz9cm2m0ucxajj2/Frontify%20Guidelines.png?raw=1</a>;
      if (!guidelineStr) return guideline;
      try{
          const guidelineObj = JSON.parse(guidelineStr);
          if (guidelineObj.guidelines) guideline =  <a href={guidelineObj.guidelines}>{guidelineObj.guidelines}</a>;
          if (guidelineObj.additional_guidelines) guideline =  <>{guidelineObj.guidelines ? <>{guideline}<br/></>: ''}<a href={guidelineObj.additional_guidelines}>{guidelineObj.additional_guidelines}</a></>;
      } catch (e) {console.log('error', e)}
      return guideline;
  }
  if (isLoading){
      return (<Card style={{padding: "15px", minHeight: "400px", position: "relative"}} spacing={2}><div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(255,255,255,0.5)", zIndex: 1000, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
         </div></Card>)
  }
  const heroTags = hero?.tags?.split(',').map(term=>term.trim());
  return (
   <Grid container spacing={3} justify={'center'}>
   <Grid item style={{ width: "100%" }} id="canvasGrid">
      <Card style={{padding: "15px"}} spacing={2}>
      <Grid container spacing={2}>
            <Grid item xs={12} sm={7} lg={8}>
               <Box component="span" m={1} className={classes.overlaymain}>
                  {selectedRequest?.parent_request_is_hero_requested_re_edit === "1" ? <Chip label="Redo" style={{ backgroundColor: "rgb(0, 172, 81)" }} size="small" className={classes.tag} /> : ''}
                  {selectedRequest?.is_hero_requested_re_edit === "1" ? <Chip label="Re-Edit" style={{ backgroundColor: "rgb(0, 172, 81)" }} size="small" className={classes.tag} /> : ''}
                  {selectedRequest?.status === "Rejected By Editor" ? <Chip label="Rejected By Editor" style={{ backgroundColor: "rgba(244, 106, 106, 0.93)" }} size="small" className={classes.tag} /> : ''}
                  {(heroTags && heroTags.includes("vip")) ? <Chip label="VIP" color="primary" size="small" className={classes.tag} /> : ''}
                  {(heroTags && heroTags.includes("expedite")) ? <Chip label="Expedite" color="primary" size="small" className={classes.tag} /> : ''}
                  {(heroTags && heroTags.includes("expedited qa")) ? <Chip label="Expedited QA" color="primary" size="small" className={classes.tag} /> : ''}
                  {(heroTags && heroTags.includes("on hold")) ? <Chip label="On Hold" color="default" size="small" className={classes.tag} /> : ''}
                  {(heroTags && heroTags.includes("test")) ? <Chip label="test" color="default" size="small" className={classes.tag} /> : ''}
                  {(heroTags && heroTags.includes("move forward")) ? <Chip label="Move Forward" color="default" size="small" className={classes.tag} /> : ''}
                  {(heroTags && heroTags.includes("pilot")) ? <Chip label="Pilot" color="primary" size="small" className={classes.tag} /> : ''}
               </Box>
            </Grid>
            <Grid item xs={12} sm={5} lg={4}>
               {hero?.team?.team_preferences &&  <Typography style={{display: "flex", alignItems: "center", gap: "5px"}} variant="subtitle2" color="inherit">
               <b>Quality Preferences: </b>
               <img style={{width: "30px"}} src={hero?.team?.team_preferences === 'quality' ? QualityPreferencesIcon : EaseOfUsePreferencesIcon} alt="" />
               </Typography>}
            </Grid>
            <Grid item xs={12} sm={7} lg={8}>
               <FieldNormal labelWidth={2} name="Name" value={`${hero.first_name} ${hero.last_name}`} />
               <Grid container spacing={2}>
                  <Grid item style={{marginBottom: "0px"}} xs={12} sm={5}>
                     <FieldNormal name="HHID" fullWidth={true} bottomSpacing={0} value={<a href={`/heroes/detail/${hero.id}`}>{hero.public_id}</a>} />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                  {hero?.team && <FieldNormal name="Team" bottomSpacing={0} fullWidth={true} value={<a href={`/teams/detail/${hero?.team?.public_id}`}>{hero?.team?.name}</a>} />}
                  </Grid>
               </Grid>
            </Grid>
            <Grid item xs={12} sm={5} lg={4}>
               <FieldNormal name="Resubmit Count" labelWidth={7} value={selectedHero?.resubmit_count} />
               {/* {(selectedRequest?.parent_request_id || selectedRequest?.is_hero_requested_re_edit === "1") &&
                     <Field name="Estimated Delivery Date" value={parseAndFormatDateTime(selectedRequest?.updated_at, true)} />
               } */}
               {selectedHero?.editor?.name && <FieldNormal labelWidth={3} bottomSpacing={0} name="Editor" value={
                     <>
                     <a href={`/editors/detail/${selectedHero?.editor?.id}`}>{selectedHero?.editor?.name}</a>
                     </>
               } />}
            </Grid>
            <Grid item xs={12} sm={12}>
               <Card id="canvasContainer" style={{ position: "relative", display: "flex", justifyContent: "center" }}>
                  <>{(showGuidelineImg && showGuideline === 'standard') ? (<div style={{ background: `url(${guidelineImg.replace('?dl=0', '?raw=1')})`, backgroundSize: "contain", backgroundPosition: "center", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }} className='guidleline-image'></div>) : ""}</>
                  <div>{(showGuidelineImg && showGuideline === 'additional') ? (<div style={{ background: `url(${addGuidelineImg})`, backgroundSize: "contain", backgroundPosition: "center", backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat' }} className='guidleline-image'></div>) : ''}</div>
                  <canvas width="800px" height="350px" id="c" />
               </Card>
            </Grid>
         <Grid item xs={12} sm={6}>
            {imageMetadata && <div>
               <Typography variant="h6" color="primary" style={{ marginBottom: 6 }}>
                     Image Metadata
               </Typography>
               <Typography variant="subtitle2" color="inherit">
                     <b> Size:</b> {imageFileSize}mb
               </Typography>
               <Typography variant="subtitle2" color="inherit">
                     <b> Dimensions:</b> {imageMetadata.width} x {imageMetadata.height}
               </Typography>
               <Typography variant="subtitle2" color="inherit">
                     <b> Orientation:</b> {imageMetadata.orientation}
               </Typography>
               {imageMetadata.dpi && (
                     <Typography variant="subtitle2" color="inherit">
                        <b>DPI:</b> {Math.trunc(imageMetadata.dpi)}
                     </Typography>
               )}
               {imageMetadata.colorProfile && (
                     <Typography variant="subtitle2" color="inherit">
                        <b>Color Profile:</b> {imageMetadata.colorProfile}
                     </Typography>
               )}
               {imageMetadata.whiteBalance && (
                     <Typography variant="subtitle2" color="inherit">
                        <b>White Balance:</b> {imageMetadata.whiteBalance}
                     </Typography>
               )}
               {imageMetadata.cameraBrand && (
                     <Typography variant="subtitle2" color="inherit">
                        <b>Camera Make:</b> {imageMetadata.cameraBrand}
                     </Typography>
               )}
               {imageMetadata.cameraModel && (
                     <Typography variant="subtitle2" color="inherit">
                        <b>Camera Model:</b> {imageMetadata.cameraModel}
                     </Typography>
               )}
               {imageMetadata.flash && (
                     <Typography variant="subtitle2" color="inherit">
                        <b>Flash:</b> {imageMetadata.flash}
                     </Typography>
               )}
            </div>}
         </Grid>
         <Grid item xs={12} sm={6}>
            <Field name="Team Notes" value={hero?.team?.notes} />
         </Grid>
         <Grid item xs={12} sm={12}>
            <CardActions style={{padding: "5px", flexWrap: "wrap"}}>
               <React.Fragment>
                  <Button size="medium"
                        variant='contained'
                        color="primary"
                        onClick={() => setShowAssignEditorModal(true)}>
                        Assign Editor
                  </Button>
                  <Button size="medium"
                        color="primary"
                        onClick={() => setShowRequestResubmitModal(true)}
                        >
                        Request Resubmit
                  </Button>
               </React.Fragment>
               {selectedHero.hero.tags && selectedHero.hero.tags.includes('on hold') ?
                  <Button size="medium"
                     color="primary"
                     onClick={() => updateHold(selectedHero.hero, false)}>
                     Remove Hold
                  </Button>
                  : <Button size="medium"
                     color="primary"
                     onClick={() => setShowHoldModal(true)}>
                     Put on Hold
                  </Button>}
                  {/* Button that links to '/teams/compare-heroes-photos/HHT-35044081' */}
               <Button size="medium"
                  color="primary"
                  as="a"
                  target="_blank"
                  href={`/teams/compare-heroes-photos/${selectedHero.team.public_id ?? selectedHero.hero.public_id}`}>
                  Compare Photos
               </Button>
               {selectedHero.hero.tags && selectedHero.hero.tags.includes('test') &&
                  <Button size="medium"
                        color="secondary"
                        style={{color: "red"}}
                        onClick={() => deleteRequest(selectedHero)}>
                        Delete
                  </Button>}
            </CardActions>
         </Grid>
         <Grid item xs={12} sm={12} lg={8}>
            {selectedRequest && <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                     <Typography className={classes.heading}>Background</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} lg={12} >
                              {selectedRequest.background_color ? <Field name="Background Color" value={selectedRequest.background_color ? rgbaToHex(selectedRequest?.background_color) : ''} /> :
                              <Field name="Background Name" value={selectedRequest?.background_name} />}
                              <Field name="Shape Value" value={selectedRequest?.shape_value} />
                              <Field name="Outline" value={selectedRequest?.is_outline === "1" ? 'Yes' : 'No'} />
                              <Field name="Color Correction" value={selectedRequest?.color_correction_value || "Normal"} />
                        </Grid>  
                        <Grid item xs={12} sm={12} lg={12}>
                           <StyleSelectorBackground request={selectedHero.request} />
                        </Grid>
                     </Grid>
                  </AccordionDetails>
            </Accordion>} 
         </Grid>
         <Grid item xs={12} sm={12}>
                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMore />}
                                                        aria-controls="panel1a-content"
                                                        id="comments"
                                                        ex
                                                    >
                                                        <Typography className={classes.heading}>Comments ({selectedHero.request.comments.length || 0})</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} >
                                                                <CommentsView comments={selectedHero.request.comments} />
                                                            </Grid>  </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
       </Grid>
       </Card>
       {/* Display image metadata */}
       
   </Grid>
   
</Grid>
  )
}

export default AssignView;