import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import { wordpressBaseUrl } from 'settings';

export const BACKGROUND = {
  LIBRARY: 'library',
  SOLID_COLOR: 'solid-color',
  GRADIENTS: 'gradients',
  OFFICE: 'office'
}
export const COLOR_TREATMENT = {
  BLACK_WHITE: 'Black & White',
  STANDARD: 'Standard Color'
}
export const SHAPES = {
  RECTANGLE : 'Rectangle',
  CIRCLE : 'Circle',
  SMALL_CIRCLE: 'Small circle',
  ABSTRACT: 'Abstract',
  SQUARE: 'Square',
}
export const SAMPLES = {
  OFFICE: [
    { name: 'White', id: "bg_thumb_white", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_sample_white.jpg', custom_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_thumb_white-1.jpg', dropbox_url: 'https://www.dropbox.com/s/s9oml5nuqjvx133/bg_white.png?dl=0' },
    { name: 'Off White', id: "bg_thumb_off_white", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_sample_off-white.jpg', custom_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_thumb_off-white.jpg', dropbox_url: 'https://www.dropbox.com/s/rknrv4d698izcxl/bg_off-white.png?dl=0' },
    { name: 'Gray', id: "bg_thumb_grey", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_sample_grey.jpg', custom_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_thumb_grey.jpg', dropbox_url: 'https://www.dropbox.com/s/ocj4i5mov74i7ho/bg_grey.png?dl=0' },
    { name: 'Light Gray', id: "bg_thumb_light_grey", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_sample_light_grey.jpg', custom_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_thumb_light_grey.jpg', dropbox_url: 'https://www.dropbox.com/s/8edbd26evihv8zx/bg_light_grey.png?dl=0' },
    { name: 'Dark Gray', id: "bg_thumb_dark_grey", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_sample_dark_grey.jpg', custom_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_thumb_dark_grey.jpg', dropbox_url: 'https://www.dropbox.com/s/iof5ij87bdmkt6k/bg_dark_grey.png?dl=0' },
    { name: 'Blue', id: "bg_thumb_blue", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_sample_blue.jpg', custom_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_thumb_blue.jpg', dropbox_url: 'https://www.dropbox.com/s/h6shdzr0a32hb5f/bg_blue.png?dl=0' },
    { name: 'Orange', id: "bg_thumb_orange", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_sample_orange.jpg', custom_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/01/bg_thumb_orange.jpg', dropbox_url: 'https://www.dropbox.com/s/01lgn7xc1ymid12/bg_orange.png?dl=0' },
  ],
  SHAPE: [
    { name: SHAPES.RECTANGLE, id: "shape_rectangle", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/07/thumbnail_shape_square-300x300.png' },
    { name: SHAPES.CIRCLE, id: "shape_circle", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/07/thumbnail_shape_circle-300x300.png', shape_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/07/big-circle.png', shape_half_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/07/big-circle-half.png' },
    { name: SHAPES.SMALL_CIRCLE, id: "shape_small_circle", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/07/thumbnail_shape_small_circle-300x300.png', shape_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/07/small-circle.png', shape_half_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/07/small-circle-bottom.png' },
    { name: SHAPES.ABSTRACT, id: "shape_abstract", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/07/thumbnail_shape_abstract-300x300.png', shape_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/07/custom.png', shape_half_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/07/custom-half.png' },
    {name: SHAPES.SQUARE, shape: SHAPES.SQUARE, id: "shape_square", sample: wordpressBaseUrl + '/wp-content/uploads/2021/07/thumbnail_shape_abstract-300x300.png', shape_image_path: wordpressBaseUrl + '/wp-content/themes/salient-child/img/style-selector/shapes/shape_square.png'}
  ],
  COLOR_TREATMENT: [
    { name: COLOR_TREATMENT.STANDARD, id: "standard_color", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/07/color-treatment-300x300.jpg', color_correction_path: wordpressBaseUrl + '/wp-content/uploads/2021/07/girl.png' },
    { name: COLOR_TREATMENT.BLACK_WHITE, id: "black_and_white", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/07/thumbnail_color_black_and_white-300x300.png', color_correction_path: wordpressBaseUrl + '/wp-content/uploads/2021/07/girl-bnw.png' }
  ],
  OUTLINE: [
    { name: 'Yes', id: "outline", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/07/outline-1-300x300.jpg', outline_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/07/outline2.png' },
    { name: 'No', id: "no_outline", sample:  wordpressBaseUrl + '/wp-content/uploads/2021/07/color-treatment-300x300.jpg', outline_image_path: wordpressBaseUrl + '/wp-content/uploads/2021/07/girl.png' },
  ],
  GIRL: "/wp-content/uploads/2021/07/girl.png",

};


export default function StyleSelectorBackground({ request }) {
  let bgStyles = {};
  // console.log('request', request)
  if (request.background_color) bgStyles = { ...bgStyles, backgroundColor: request.background_color };
  if (request.background_url) bgStyles = { ...bgStyles, backgroundImage: "url(" + (request.background_url.includes("http") ? request.background_url.replace('?dl=0', '?raw=1') : `${wordpressBaseUrl}${request.background_url.replace('?dl=0', '?raw=1')}`) + ")", backgroundSize: "contain",backgroundRepeat: "no-repeat", backgroundPositionX:'50%' };
  if ((request.background_color === '#ffffff' || request.background_name?.toLowerCase() === 'white') && !request.shape_value && !request.outline && !request.color_correction_value) {
    return '';
  }
  return (
    <><div style={{  width: '308px', height: '206px', position: 'relative', ...bgStyles }}>
      {parseInt(request.is_outline) === 1 && (
       <img style={{ position: 'absolute', zIndex: 9, width: '100%', height: '100%',}} src={(SAMPLES.OUTLINE.find(sct => sct.name === 'Yes'))?.outline_image_path} alt="" />
      )}
      {request.shape_value === SHAPES.CIRCLE && (
        <img style={{ position: 'absolute', zIndex: 11, width: '100%', height: '100%' }} src={(SAMPLES.SHAPE.find(sct => sct.name === SHAPES.CIRCLE))?.shape_image_path} alt="" />
      )}
      {request.shape_value === SHAPES.SQUARE && (
        <img style={{ position: 'absolute', zIndex: 11, width: '100%', height: '100%' }} src={(SAMPLES.SHAPE.find(sct => sct.name === SHAPES.SQUARE))?.shape_image_path} alt="" />
      )}
      {request.shape_value === SHAPES.SMALL_CIRCLE && (
        <>
        <img  style={{ position: 'absolute', zIndex: 8, width: '100%', height: '100%' }} src={(SAMPLES.SHAPE.find(sct => sct.name === SHAPES.SMALL_CIRCLE))?.shape_image_path} alt="" />
        <img style={{ position: 'absolute', zIndex: 11, width: '100%', height: '100%' }} src={(SAMPLES.SHAPE.find(sct => sct.name === SHAPES.SMALL_CIRCLE))?.shape_half_image_path} alt="" />
        </>
      )}
      {request.shape_value === SHAPES.ABSTRACT && (
        <>
        <img style={{ position: 'absolute', zIndex: 8, width: '100%', height: '100%' }} src={(SAMPLES.SHAPE.find(sct => sct.name === SHAPES.ABSTRACT))?.shape_image_path} alt="" />
        <img style={{ position: 'absolute', zIndex: 11, width: '100%', height: '100%' }} src={(SAMPLES.SHAPE.find(sct => sct.name === SHAPES.ABSTRACT))?.shape_half_image_path} alt="" />
        </>
      )}
      {
        request.color_correction_image_path &&
          <img style={{ position: 'absolute', bottom: 0, left: "0", zIndex: 10,  maxWidth: '100%', height: '100%' }} src={request.color_correction_image_path} alt="" /> 
      }
      {
        (!request.color_correction_image_path && ( request.shape_value || parseInt(request.is_outline) === 1)) && 
        <img style={{ position: 'absolute', bottom: 0, left: "0", zIndex: 10,  maxWidth: '100%', height: '100%' }} src={wordpressBaseUrl + SAMPLES.GIRL} alt="" />
      }
    </div><br/></>
  )
}