import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Editor } from '@tinymce/tinymce-react';

import { wordpressBaseUrl } from 'settings';
import { Checkbox, CircularProgress, FormControlLabel, TextareaAutosize } from '@material-ui/core';
import { getMetaValue } from 'views/Utils/helpers';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

export default function HoldModal(props) {
    const { open, onClose, selectedHero, handleSubmit } = props;
   //  const [customSectionTitle, setCustomSectionTitle] = React.useState(null);
    const [isError, setIsError] = React.useState(false);
    const [error, setError] = React.useState("");
    const [value, setValue] = React.useState();
    const [isVisible, setIsVisible] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setIsError(false);
        setError("");
      //   setCustomSectionTitle(null)
         // const metaValue = getMetaValue(teamMeta, open);
         // setValue(metaValue);
      //   load();
    }, [open]);

    const submit = async () => {
        setIsLoading(true);
        handleSubmit(selectedHero.hero, true, value, selectedHero.request)
        // const url = `${wordpressBaseUrl}/wp-json/hh-api/upsert-team-metadata`;
        // const valueResponse = await axios.post(url, {
        //     // team_id: team.id,
        //     key: open,
        //     value: value
        // });
        // window.location.reload();
    }

    return (
        open
            ? ReactDOM.createPortal(
                <div>
                    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="lg" disableEnforceFocus={true}>
                        <DialogTitle id="form-dialog-title">Add On Hold Comments</DialogTitle>
                        <DialogContent>
                            {isLoading ?
                                <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", height: "100%", padding: "40px" }}>
                                    <CircularProgress />
                                </div> :
                                <>
                                    <DialogContentText>
                                    Leave a note why this submission is On Hold below.
                                    </DialogContentText>
                                    <TextareaAutosize
                                       id="outlined-textarea"
                                       label={ "Enter Comment"}
                                       fullWidth
                                       minRows={5}
                                       style={{width: "100%", fontFamily: "'Poppins', sans-serif"}}
                                       variant="outlined"
                                       error={ isError}
                                       value={value}
                                       onChange={(e) => {
                                             setValue(e.target.value);
                                             setError("");
                                             setIsError(false);
                                             //if (e.target.value == "" || e.target.value < 1) setError("Amount should be a valid number");
                                       }}
                                    />
                            <Typography variant="p" component="p" color="error">
                                {error}
                            </Typography>
                                <br />
                                <br />
                                </>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary">Cancel</Button>
                            <Button onClick={submit} color="primary">Submit</Button>
                        </DialogActions>
                    </Dialog>
                </div>, document.body)
            : null)
}

HoldModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    message: PropTypes.string,
    title: PropTypes.string
};