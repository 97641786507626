import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { wordpressBaseUrl } from 'settings';
import { Checkbox, FormControlLabel } from '@material-ui/core';


export default function ModalAssignEditor(props) {
    const { open, onClose, team, request, hero, onSubmit, editors } = props;
    const [selectedEditor, setSelectedEditor] = React.useState(team.editor_id);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isConfirmed, setIsConfirmed] = React.useState(false);

    useEffect(() => {
        if(!team.notes) {
            setIsConfirmed(true);
        } else {
            setIsConfirmed(false);
        }
    }, [team.notes]);

    const acceptOptionReached = (editorOption) => {
        // console.log('editorOption', editorOption)
        if (!editorOption?.incoming_cap){
            return false;
        }
        if (parseInt(editorOption.incoming_cap) <= parseInt(editorOption.request_count)){
            return true;
        }
        return false;
    }    

    const handleSelectedEditor = (value) => {
        // console.log('value', value)
        if (acceptOptionReached(value)) {
            alert("Editor has reached their Incoming cap!"); 
            setSelectedEditor(null)
        } else {
            setSelectedEditor(value ? value.id : null);
        }
    }

    

    return (
        open
            ? ReactDOM.createPortal(
                <div>
                    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Assign Editor</DialogTitle>
                        <DialogContent>
                            {/* <DialogContentText>
                            </DialogContentText> */}

                            <Autocomplete
                                id="editor-select"
                                value={editors.find(editor => editor.id === selectedEditor) || null} // Bind to state value
                                onChange={(e,value) => { handleSelectedEditor(value) }}
                                options={editors}
                                getOptionLabel={(option) => `${option.name} (${option.request_count}) ${option.active === "1" ? "" : " (PAUSE)"}`}
                                renderOption={(option) => (
                                    <React.Fragment>
                                      <span style={acceptOptionReached(option) ? {color: "red"} : {}}>{`${option.name} (${option.request_count}) ${option.active === "1" ? "" : " (PAUSE)"}`}</span>
                                    </React.Fragment>
                                  )}
                                style={{ width: 300 }}
                                renderInput={(params) => isLoading ? "Loading Editors..." : <TextField {...params} label={selectedEditor ? editors.filter(e => e.id == selectedEditor)[0].name : "Choose an editor"} variant="outlined" />}
                            />
                            <br/>
                            {team.notes && <div>
                                <h3>Team Guidelines</h3>
                                <p>{team.notes}</p>
                                <br/><br/>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isConfirmed}
                                        onChange={() => {setIsConfirmed(!isConfirmed); }}
                                        color="primary"
                                    /> }
                                    label="I have read the team notes."
                                /></div>}

                        </DialogContent>
                        <DialogActions>
                            <div style={{ flex: '1 0 0' }} />
                            <Button onClick={onClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => onSubmit(selectedEditor)} disabled={!selectedEditor || !isConfirmed} color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>, document.body)
            : null)
}

ModalAssignEditor.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    message: PropTypes.string,
    title: PropTypes.string
};