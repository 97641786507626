import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';

import { ACCOUNT_INITIALISE, LOGIN, LOGOUT } from '../store/actions';
import axios from 'axios';
import accountReducer from '../store/accountReducer';
import Loader from '../component/Loader/Loader';

import { wordpressBaseUrl } from 'settings';
import { getUsermeta, updateUserMeta } from 'views/Utils/helpers';

const initialState = {
    isLoggedIn: false,
    isInitialised: false,
    user: null,
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    console.log(serviceToken)
    const decoded = jwtDecode(serviceToken);
    return decoded.exp > Date.now() / 1000;
};

const setSession = (session) => {
    if (session?.token) {
        localStorage.setItem('serviceToken', JSON.stringify(session));
        axios.defaults.headers.common.Authorization = `Bearer ${session.token}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

const JWTContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => {},
});

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const login = async (email, password) => {
        const response = await axios.post(`${wordpressBaseUrl}/wp-json/jwt-auth/v1/token`, { username: email, password });
        // session = {token, user_email, user_nicename}
        var session = response.data;
        // console.log("response", response);
        setSession(session);
        const lastUrl = await getUsermeta('last_url_during_logout');
        if (lastUrl && !lastUrl.includes('login')){
            window.location.href = lastUrl;
        }
        setTimeout(()=>{
            dispatch({
                type: LOGIN,
                payload: {
                    user:session,
                },
            });
        }, 1000);
        
    };

    const logout = async() => {
        const lastUrl = window.location.href;
        if (!lastUrl.includes('login')) {
            await updateUserMeta('last_url_during_logout', lastUrl);
        }
        setSession(null);
        // console.log('window.location.href', window.location.href)
        dispatch({ type: LOGOUT });
    };

    useEffect(() => {
        const init = async () => {
            try {
                let session = window.localStorage.getItem('serviceToken');
                if(session) {
                    session = JSON.parse(session);
                }
                // console.log("init", session)
                if (session && verifyToken(session.token)) {
                    setSession(session);
                    //const response = await axios.get('/api/account/me');
                    //const { user } = response.data;
                    dispatch({
                        type: ACCOUNT_INITIALISE,
                        payload: {
                            isLoggedIn: true,
                            user: session,
                        },
                    });
                } else {
                    dispatch({
                        type: ACCOUNT_INITIALISE,
                        payload: {
                            isLoggedIn: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: ACCOUNT_INITIALISE,
                    payload: {
                        isLoggedIn: false,
                        user: null,
                    },
                });
            }
        };

        init();
    }, []);

    if (!state.isInitialised) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
