import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress,
    TextareaAutosize
} from '@material-ui/core';

import { wordpressBaseUrl } from 'settings';

export default function NotificationModal(props) {
    const { open, onClose, editNotification, setEditNotification, afterSave } = props;
    const [title, setTitle] = React.useState();
    const [description, setDescription] = React.useState();
    const [year, setYear] = React.useState();
    const [month, setMonth] = React.useState();
    const [message, setMessage] = React.useState();
    const editorRef = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState();
    
    
    useEffect(()=>{
        setErrors();
        if (!editNotification) {
            setMessage();
        }
    }, [open]);

    useEffect(()=> {
        if (editNotification) {
            console.log('editNotification', editNotification)
            setMessage(editNotification.message);
            // setDescription(editNotification.description);
            // setYear(editNotification.year);
            // setMonth(editNotification.month);
        } else {
            setMessage();
        }
    }, [editNotification])
    
    function isEmptyObject(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      }

    const validateInput = () => {
        let allerrors = {};
        if (!message) {
            allerrors.message = "Message field is required!";
        }  
        // if (!description) {
        //     allerrors.description = "Description field is required!";
        // }  
        // if (!year) {
        //     allerrors.year = "Year field is required!";
        // }
        // if (!month) {
        //     allerrors.month = "Month field is required!";
        // }
        if (isEmptyObject(allerrors)){
            return false;
        }
        setErrors(allerrors);
        return allerrors;
    }
    
    const submit = async () => {
        // setDescription(editorRef.current.getContent())
        setLoading(true)
        setErrors(null);
        let allerrors = validateInput();
        if (allerrors){
            setLoading(false);
            return;
        }
        const url = `${wordpressBaseUrl}/wp-json/hh-api/save-notification`;
        const input = {
            message,
            type : 'production-update',
        };
        if(editNotification && editNotification.id) input.id = editNotification.id;
        const response = await axios.post(url, input);

        console.log("update team response", response.data);
        if (response.data.success) {
            afterSave();
            setMessage();
            setLoading(false)
        }
        // window.location.reload()

    }

    const handleClose = () => {
        setMessage();
        onClose();
    }

    return (
        open
            ? (<div>
                    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title2" maxWidth={'md'} fullWidth={true} disableEnforceFocus={true}>
                        <DialogTitle id="form-dialog-title2">{editNotification ? "Edit" : "Add"} Production Update</DialogTitle>
                        <DialogContent>
                            {/* <TextField
                                fullWidth={true}
                                label="Title"
                                type="textarea"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                helperText={errors && errors['title'] ? errors['title'] : ''}
                                error={errors && errors['title'] ? true : false}
                            /> */}
                            <TextareaAutosize
                                 id="outlined-textarea"
                                 label={ "Enter Comment"}
                                 fullWidth
                                 minRows={5}
                                 style={{width: "100%", fontFamily: "'Poppins', sans-serif"}}
                                 variant="outlined"
                                 // error={ isError}
                                 value={message}
                                 onChange={(e) => {
                                       setMessage(e.target.value);
                                       setErrors();
                                       // setIsError(false);
                                 }}
                              />
                            <br /><br />
                            {/* <Editor
                              tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce.min.js'}
                              onInit={(evt, editor) => editorRef.current = editor}
                              initialValue={description}
                              init={{
                                 force_br_newlines: true,
                                 force_p_newlines: false,
                                 forced_root_block: '',
                                 height: 500,
                                 menubar: false,
                                 plugins: [
                                    'advlist autolink lists link charmap preview anchor',
                                    'image',
                                    'searchreplace visualblocks',
                                    'insertdatetime table paste link'
                                 ],
                                 toolbar: 'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter |' +
                                    'alignright alignjustify | link | bullist numlist outdent indent table tablecellvalign | ' +
                                    'removeformat | help | image ',
                                 content_style: 'body { font-size:14px }',
                                 default_link_target: '_blank',
                                 images_file_types: 'jpg,svg,webp,png,jpeg',
                                 file_picker_types: 'media',
                                 images_upload_handler: uploadImage,
                              }}
                           />
                           <br /><br /> */}

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={submit} color="primary">
                            {loading ? <CircularProgress size={24} style={{ margin:"auto" }}/> : "Submit"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>)
            : null)
}

NotificationModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};