import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress
} from '@material-ui/core';

import { Editor } from '@tinymce/tinymce-react';
import { wordpressBaseUrl } from 'settings';

const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
];
export default function AddReleaseNoteModal(props) {
    const { open, onClose, editNote } = props;
    const [title, setTitle] = React.useState();
    const [description, setDescription] = React.useState();
    const [year, setYear] = React.useState();
    const [month, setMonth] = React.useState();
    const [status, setStatus] = React.useState();
    const editorRef = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState();
    
    
    useEffect(()=>{
        setErrors();
    }, [open]);

    useEffect(()=> {
        if (editNote) {
            console.log('editNote', editNote)
            setTitle(editNote.title);
            setDescription(editNote.description);
            setYear(editNote.year);
            setMonth(editNote.month);
        }
    }, [editNote])
    
    function isEmptyObject(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      }

    const validateInput = () => {
        let allerrors = {};
        if (!title) {
            allerrors.title = "Title field is required!";
        }  
        if (!description) {
            allerrors.description = "Description field is required!";
        }  
        if (!year) {
            allerrors.year = "Year field is required!";
        }
        if (!month) {
            allerrors.month = "Month field is required!";
        }
        if (isEmptyObject(allerrors)){
            return false;
        }
        setErrors(allerrors);
        return allerrors;
    }

//    const handleEditorChange = (content, editor) => {
//       // console.log('content', content.length)
//       setDescription(content)
//    }

   const uploadImage = async (blobInfo, success, failure) => {
      // Create a FormData object to hold the image file
      const formData = new FormData();
      formData.append('file', blobInfo.blob());
      console.log(blobInfo.blob(), blobInfo.filename());
      try {
          const url = `${wordpressBaseUrl}/wp-json/hh-api/upload-image`;
        // Make an API call to your custom image upload endpoint
        const response = await axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}});
  
        // Handle the response from your server
        const imageUrl = response.data.uploaded_url; // Assuming your server returns the URL of the uploaded image
        success(imageUrl);
      } catch (error) {
        console.error('Image upload failed:', error);
        failure('Image upload failed');
      }
  }
    
    const submit = async () => {
        setDescription(editorRef.current.getContent())
        setLoading(true)
        setErrors(null);
        let allerrors = validateInput();
        if (allerrors){
            setLoading(false);
            return;
        }
        console.log('description', description);
        const url = `${wordpressBaseUrl}/wp-json/hh-api/save-release-note`;
        const input = {
            title,
            description : editorRef.current.getContent(),
            year,
            month,
            status
        };
        if(editNote && editNote.id) input.id = editNote.id;
        const reponse = await axios.post(url, input);

        console.log("update team response", reponse.data);

        window.location.reload()

    }
    const dateObj = new Date();
    const currentMonth = dateObj.getMonth() + 1;

    return (
        open
            ? (<div>
                    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title2" maxWidth={'lg'} fullWidth={true} disableEnforceFocus={true}>
                        <DialogTitle id="form-dialog-title2">Add Release Note</DialogTitle>
                        <DialogContent>
                           <FormControl variant="outlined" style={{width:"100%"}}>
                                <InputLabel id="year-label" style={{background: 'white'}}>Year</InputLabel>
                                <Select
                                labelId="demo-mutiple-chip-label"
                                id="year-select"
                                value={year}
                                onChange={(e) => {setYear(e.target.value);setMonth()}}
                                helperText={errors && errors['year'] ? errors['year'] : ''}
                                error={errors && errors['year'] ? true : false}
                            >
                                <MenuItem value={'2024'}>2024</MenuItem>
                                <MenuItem value={'2023'}>2023</MenuItem>
                                <MenuItem value={'2022'}>2022</MenuItem>
                            </Select>
                            </FormControl>
                            <br /><br />
                            <FormControl variant="outlined" style={{width:"100%"}}>
                                <InputLabel id="month" style={{background: 'white'}}>Month</InputLabel>
                                <Select
                                labelId="demo-mutiple-chip-label"
                                id="month-select"
                                value={month}
                                onChange={(e) => setMonth(e.target.value)}
                                helperText={errors && errors['month'] ? errors['month'] : ''}
                                error={errors && errors['month'] ? true : false}
                            >
                                {Months.map((month, ind)=>{
                                    if (year == '2024' && currentMonth >= (ind + 1)){
                                        return (<MenuItem value={ind+1}>{month}</MenuItem>);
                                    } else if (year != '2024'){
                                        return (<MenuItem value={ind+1}>{month}</MenuItem>);
                                    }
                                })}
                                     
                                {/* <MenuItem value={'1'}>January</MenuItem>
                                <MenuItem value={'2'}>February</MenuItem>
                                <MenuItem value={'3'}>March</MenuItem>
                                <MenuItem value={'4'}>April</MenuItem>
                                <MenuItem value={'5'}>May</MenuItem>
                                <MenuItem value={'6'}>June</MenuItem>
                                <MenuItem value={'7'}>July</MenuItem>
                                <MenuItem value={'8'}>August</MenuItem>
                                <MenuItem value={'9'}>September</MenuItem>
                                <MenuItem value={'10'}>October</MenuItem>
                                <MenuItem value={'11'}>November</MenuItem>
                                <MenuItem value={'12'}>December</MenuItem> */}
                            </Select>
                            </FormControl>
                            <br /><br />
                            <TextField
                                fullWidth={true}
                                label="Title"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                helperText={errors && errors['title'] ? errors['title'] : ''}
                                error={errors && errors['title'] ? true : false}
                            />
                            <br /><br />
                            <Editor
                                 tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce.min.js'}
                                 onInit={(evt, editor) => editorRef.current = editor}
                                 initialValue={description}
                                //  value={description}
                                 init={{
                                    force_br_newlines: true,
                                    force_p_newlines: false,
                                    forced_root_block: '',
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                       'advlist autolink lists link charmap preview anchor',
                                       'image',
                                       'searchreplace visualblocks',
                                       'insertdatetime table paste link'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                       'bold italic backcolor | alignleft aligncenter |' +
                                       'alignright alignjustify | link | bullist numlist outdent indent table tablecellvalign | ' +
                                       'removeformat | help | image ',
                                    content_style: 'body { font-size:14px }',
                                    default_link_target: '_blank',
                                    images_file_types: 'jpg,svg,webp,png,jpeg',
                                    file_picker_types: 'media',
                                    images_upload_handler: uploadImage,
                                    // image_dimensions: true,
                                    // object_resizing: true // Allow resizing by dragging
                                 }}
                                //  onEditorChange={handleEditorChange}
                           />
                           
                            {/* <FormControl variant="outlined" style={{width:"100%"}}>
                                <InputLabel id="demo-simple-select-outlined-label" style={{background: 'white'}}>Status</InputLabel>
                                <Select
                                labelId="demo-mutiple-chip-label"
                                id="demo-mutiple-chip"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <MenuItem value={'draft'}>Draft</MenuItem>
                                <MenuItem value={'published'}>Published</MenuItem>
                            </Select>
                            </FormControl> */}
                            <br /><br />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={submit} color="primary">
                            {loading ? <CircularProgress size={24} style={{ margin:"auto" }}/> : "Submit"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>)
            : null)
}

AddReleaseNoteModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};