import React, { useCallback } from 'react';
import { Box, Button } from '@material-ui/core';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

function getDefaultServerSideOptions(setState, getData, count, rowsPerPage, isLoading, selectableRows = 'none', onSelectedAction = null) {
  let timeoutIt = null;
  // const debouncedGetData = debounce(getData, 900);
  
  var options = {
        textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No results found',
            }
          },
        filter: false,
        onFilterChange: (changedColumn, filterList) => {
            console.log(changedColumn, filterList);
        },
        serverSide: true,
        selectableRows,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {

            return (
                <Box component="span" m={1} >
                    <Box component="span" >
                        <Button variant="contained" color="secondary" style={{marginRight: 4}} onClick={() => {
                            setSelectedRows([]);
                        } }>
                            Clear Selection
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => {
                            onSelectedAction(selectedRows);
                        } }>
                            Update Heroes
                        </Button>
                    </Box>
                </Box>
            );
        },
        //filterType: 'dropdown',
        responsive: 'vertical',
        rowsPerPage,
        //page,
        count,
        print: false,
        // onChangePage: (currentPage) => {
        //     this.setState({ page: currentPage });
        //     this.getData();
        // },
        onSearchChange: (searchText) => {
            // console.log(searchText);
             if(timeoutIt) clearTimeout(timeoutIt);
             timeoutIt = setTimeout(() => {
                setState({ searchText: searchText ? searchText : "", page: 0 });
                // setState({ searchText: searchText ? searchText : ""}, getSearchData)
            }, 1200);
        },
        onTableChange: (action, tableState) => {
            //console.log(action, tableState);
    
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
    
            switch (action) {
              case 'changePage':
                setState({ page: tableState.page }, getData);
                break;
              case 'sort':
                  console.log("action", action, tableState)
                setState({ orderBy: tableState.sortOrder.name, orderDirection: tableState.sortOrder.direction }, getData);
                //this.sort(tableState.page, tableState.sortOrder);
                break;
                case 'changeRowsPerPage':
                    setState({ rowsPerPage: tableState.rowsPerPage, page: 0 }, getData);
                    break;
              default:
                console.log('action not handled. ' + action);
            }
          },
    };

    return options;
}

function getDefaultClientSideOptions(count, isLoading, sortOrder, overrideOptions = {}) {
    const options = {
        textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No results found',
            }
          },
        filter: false,
        onFilterChange: (changedColumn, filterList) => {
            console.log(changedColumn, filterList);
        },
        serverSide: false,
        selectableRows: 'none',
        //filterType: 'dropdown',
        responsive: 'vertical',
        rowsPerPage: 100,
        //page,
        count,
        print: false,
        viewColumns: false,
        // onChangePage: (currentPage) => {
        //     this.setState({ page: currentPage });
        //     this.getData();
        // },
        sortOrder,
        // customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
        //     return <div style={{margin: 20, float: 'right'}}>{`${count} Records`}</div>},
        onTableChange: (action, tableState) => {
            //console.log(action, tableState);
    
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
    
            switch (action) {
              case 'changePage':
                //this.setState({ page: tableState.page }, this.getData);
                break;
              case 'sort':
                //this.sort(tableState.page, tableState.sortOrder);
                break;
              default:
                console.log('action not handled.');
            }
          },
        ...overrideOptions

    };

    return options;
}

export {
    getDefaultServerSideOptions,
    getDefaultClientSideOptions
}
