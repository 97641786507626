import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles, useMediaQuery, useTheme, AppBar, CssBaseline, Toolbar } from '@material-ui/core';

import { drawerWidth } from './../../store/constant';
import Header from '../MainLayout/Header';
import Sidebar from '../MainLayout/Sidebar';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    toolbar: theme.mixins.toolbar,
    content: {
        width: '100%',
        minHeight: '100vh',
        flexGrow: 1,
        /*padding: theme.spacing(3),*/
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -drawerWidth,
            //  width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.up('1500')]: {
            marginLeft: -drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    contentShift: {

        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    main: {
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
    header: {
        zIndex: 1201,
        backgroundColor: "#fff",
        color: "#000",
    },
}));
const MinimalLayout = (props) => {
    const { children, showAppBar } = props;
    const classes = useStyles();

    return <Fragment> {showAppBar &&
        <>
        <AppBar position="fixed" className={classes.header}>
            <Toolbar>
                <Header drawerOpen={false} drawerToggle={() => { }} />
            </Toolbar>
        </AppBar>
        <div style={{ marginTop: 54 }} />
        </>
        }
        {children}</Fragment>;
};

export default MinimalLayout;
