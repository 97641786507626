import React, { useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { wordpressBaseUrl, resubmitRequestOptions as optionsConfig, resubmitRequestEmailTemplateCode, resubmitRequestEmailTemplateCode_fr } from '../../settings';
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, NativeSelect, TextField } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ModalConfirmation from '../Heroes/Detail/ModalConfirmation';
import ZoomImage from 'component/_hh/ZoomImage';

import MouseOverPopover from 'component/_hh/Popover';
import { CloudDownloadOutlined, OpenInBrowserOutlined } from '@material-ui/icons';
import PhotoView from 'views/PhotoView/PhotoView';

export default function ModalRequestResubmit(props) {
    const { open, onClose, hero, request } = props;
    const [values, setValues] = useState({
    });
    const [resubmitRequestOptions, setResubmitRequestOptions] = useState(optionsConfig);
    const [customMessage, setCustomMessage] = useState(null);

    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [teamPreferences, setTeamPreferences] = React.useState(null)
    const [errors, setErrors] = React.useState([]);
    const [tier, setTier] = React.useState();

    useEffect(()=>{
        if (props.team?.id) {
            axios.get(`${wordpressBaseUrl}/wp-json/hh-api/get-team-metadata?team_id=${props.team?.id}&key=team_preferences`).then((response) => {
               if(response?.data?.metadata?.value ){
                    setTeamPreferences(response?.data?.metadata?.value)
               }
            });
        }
    }, [props.team?.id]);

    const submit = async () => {

        setIsLoading(true);

        let count = 0;
        const trueKeys = Object.keys(values).filter(key => values[key]);

        try {
            const getLangCdUrl = `${wordpressBaseUrl}/wp-json/hh-api/get-language-code`;
            const getLangCdUrlRequest = {
                hero_public_id: props.hero.public_id
            };

            const getLangCdUrlResponse = await axios.post(getLangCdUrl, getLangCdUrlRequest);
            const langCd = getLangCdUrlResponse.data.lang_cd;

            const photoErrors = trueKeys.map((key) => {
                // split first part of key to get the category
                const category = key.split("_")[0];
                // split whole second part of key to get the option key
                const optionKey = key.replace(category + "_", "");
                return {
                    key: key,
                    prob_num: ++count,
                    why: langCd === "en" ? resubmitRequestOptions[category].options.find(o => o.key == optionKey).why : resubmitRequestOptions[category].options.find(o => o.key == optionKey).why_fr,
                    how: langCd === "en" ? resubmitRequestOptions[category].options.find(o => o.key == optionKey).how : resubmitRequestOptions[category].options.find(o => o.key == optionKey).how_fr
                };
            });

            const url = `${wordpressBaseUrl}/wp-json/hh-api/send-resub-email`;
           
            const request = {
                hero_public_id: props.hero.public_id,
                request_id: props.request.id,
                template: langCd === "en" ? resubmitRequestEmailTemplateCode : resubmitRequestEmailTemplateCode_fr,
                data: photoErrors,
                custom_message: (customMessage && customMessage.length > 0) ? customMessage : null,
                tier: tier
            };
            
            // console.log('request', request)
            if (request.data.length === 0 && !request.custom_message) {
                setErrors(["Please select at least one of the reason or write custom notes!"]);
                setShowConfirmation(false);
                setIsLoading(false);
                return;
            }
            if (!request.tier) {
                setErrors(["Please select tier!"]);
                setShowConfirmation(false);
                setIsLoading(false);
                return;
            }
            const response = await axios.post(url, request);
            console.log('response', response);
            onClose();
        } catch (e) {
            console.log("error sending email", e);
        }
        setIsLoading(false);
    }

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: !values[event.target.name] });
    };

    const handleCustomMessageChange = (event) => {
        setCustomMessage(event.target.value);
    };

    const imageFileName = request.filename.split("/").pop();

    return (
        open
            ? ReactDOM.createPortal(
                <div>

                    <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Request Resubmit</DialogTitle>
                        <DialogContent>
                            <div style={{display: "flex", width: "100%"}}>
                            <div style={{ flexGrow: "1" }}>
                                <div className='resubmit-photo-view' style={{display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <div className='text-center'>
                                        <p><b>Type:</b> {props.team?.id ? "Team" : "Individual"}</p>
                                        {teamPreferences && <p><b>Quality Check Preferences:</b> {teamPreferences === 'ease-of-use' ? 'Minimized Effort for My Team' : teamPreferences}</p>}
                                    </div>
                                    <p style={{maxWidth: "710px"}}>{props.team?.id && <><b>Team Notes:</b> {props.team.notes}</> }</p>
                                    <PhotoView
                                    canvasId="d"
                                    style={{flexGrow: '.75', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                            hero_public_id={props.hero.public_id}
                                            filename={imageFileName} 
                                            imageMetadata={true}
                                            imageOnly={true}/>
                                        
                                </div>
                            </div>
                            <div style={{ paddingLeft: "20px", maxWidth: "50%", height: "calc(100vh - 12rem)", overflowY: "auto", overflowX: "hidden" }}>
                                {errors?.map(error=>(<p style={{color: "red"}}>{error}</p>))}
                                <h2>Photo Issues</h2>
                                <hr />

                                {Object.keys(resubmitRequestOptions).map((key, index) => (
                                    <div key={index}>
                                        <h3>{resubmitRequestOptions[key].name}</h3>
                                        <hr />
                                        {resubmitRequestOptions[key].options.map((o, i) => (
                                            <div key={i}>
                                                <FormControlLabel
                                                    classes={{
                                                        root: "w-full",
                                                        label: "w-full"
                                                    }}
                                                    control={
                                                        <Checkbox
                                                            name={key + "_" + o.key}
                                                            checked={values[key + "_" + o.key]}
                                                            onChange={handleChange}
                                                            color="primary"
                                                        />}
                                                    label={
                                                        <>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={11}>
                                                                    <div>{o.reason}</div>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <MouseOverPopover customClass="resubmitPopover"
                                                                        content={
                                                                            <div style={{ width: "625px", padding: "1.5em", backgroundColor: "#f0f2f8" }}>
                                                                                <center><span>{o.why}</span></center>
                                                                                <br />
                                                                                <center><img style={{ width: "60%", height: "60%" }} src={o.exampleImagePath} /></center>
                                                                            </div>
                                                                        }>
                                                                        <InfoIcon />
                                                                    </MouseOverPopover>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    } />
                                            </div>
                                        ))}
                                    </div>
                                ))}

<br/><br/>
                                <TextField
                                    style={{background: "#ffff0024"}}
                                    id="outlined-multiline-static"
                                    label="Additional Notes"
                                    multiline
                                    rows={4}
                                    placeholder="Write custom notes here to provide more details to the hero in the email."
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleCustomMessageChange}
                                />
                                {errors?.map(error=>{
                                    if (error.includes('tier')) {
                                        return (<p style={{color: "red"}}>{error}</p>);
                                    }
                                })}
                                <h3>Tier: </h3>
                                <FormControl >
                                    <InputLabel htmlFor="tier-select">Tier:</InputLabel>
                                    <NativeSelect
                                    onChange={(e)=>setTier(e.target.value)}
                                    inputProps={{
                                        name: 'tier',
                                        id: 'tier-select',
                                    }}
                                    >
                                    <option aria-label="Select a Tier" value="" />
                                    <option value={2}>Tier 2: Not the best, but workable.</option>
                                    <option value={3}>Tier 3: Not workable at all. </option>
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div style={{ flex: '1 0 0' }} />
                            <Button onClick={onClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => setShowConfirmation(true)} color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <ModalConfirmation isLoading={isLoading} title={'Confirm Resubmit Request'} message={`Do you want to send request ${request.id} for resubmission?`} open={showConfirmation} onClose={() => setShowConfirmation(false)}
                        onSubmit={submit} />



                </div>, document.body)
            : null)
}

ModalRequestResubmit.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    message: PropTypes.string,
    title: PropTypes.string
};