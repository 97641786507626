import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';

export default function ModalConfirmation(props) {
    const { open, onClose, onSubmit, message, title, isLoading, html, showCommentInput } = props;
    const [comment, setComment] = React.useState(null);

    return (
        open
            ? ReactDOM.createPortal(
                <div>
                    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {message}

                                {html && <div dangerouslySetInnerHTML={{ __html: html }}></div>}

                                {showCommentInput && <TextField
                                    autoFocus
                                    margin="dense"
                                    id="comment"
                                    label="Comment"
                                    type="text"
                                    fullWidth
                                    onChange={(e) => setComment(e.target.value)}
                                />}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary">
                                Cancel
                            </Button>
                            {onSubmit && <Button onClick={() => onSubmit({comment})} disabled={isLoading} color="primary">
                                {isLoading ? <CircularProgress size={24} style={{ margin:"auto" }}/> : "Submit"}
                            </Button>}
                        </DialogActions>
                    </Dialog>
                </div>, document.body)
            : null)
}

ModalConfirmation.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    message: PropTypes.string,
    title: PropTypes.string
};