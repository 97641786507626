import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';

export default function ModalConfirmation(props) {
    const { open, onClose, onSubmit, message, title, isLoading, textInput, onTextInputChange } = props;

    return (
        open
            ? ReactDOM.createPortal(
                <div>
                    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {message}
                            </DialogContentText>
                            {onTextInputChange && <TextField

                                autoFocus
                                margin="dense"
                                id="name"
                                label="Comment"
                                type="text"
                                multiline
                                rows={3}
                                fullWidth
                                value={textInput}
                                onChange={onTextInputChange}
                            />}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={onSubmit} disabled={isLoading} color="primary">
                                {isLoading ? <CircularProgress size={24} style={{ margin:"auto" }}/> : "Submit"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>, document.body)
            : null)
}

ModalConfirmation.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    message: PropTypes.string,
    title: PropTypes.string
};