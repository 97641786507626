import React, { useState, useEffect } from 'react';

const ZoomImage = ({ src, alt, style ={} }) => {
  const [zoom, setZoom] = useState(1);
  const [altKey, setAltKey] = useState(false);
  const [cursor, setCursor] = useState({ x: 0, y: 0 });
  const [imgPosition, setImgPosition] = useState({ x: 0, y: 0 });

  const calculatePosition = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / e.target.offsetWidth) * 100;
    const y = ((e.clientY - rect.top) / e.target.offsetHeight) * 100;
    return { x, y };
  }

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === 'Alt') setAltKey(true);
    };

    const keyUpHandler = (e) => {
      if (e.key === 'Alt') setAltKey(false);
    };

    window.addEventListener('keydown', keyDownHandler);
    window.addEventListener('keyup', keyUpHandler);
  
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
      window.removeEventListener('keyup', keyUpHandler);
    };
  }, []);

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (altKey) {
      const position = calculatePosition(e);
      setCursor(position);
    }
  };

  const handleWheel = (e) => {
    if (altKey) {
      e.preventDefault();
      const newZoom = zoom - e.deltaY * 0.001;
      setZoom(newZoom);

      const position = calculatePosition(e);
      setImgPosition({
        x: position.x - cursor.x + (imgPosition.x * newZoom / zoom),
        y: position.y - cursor.y + (imgPosition.y * newZoom / zoom),
      });
    }
  };

  return (
    <div
      onWheel={handleWheel}
      style={{ overflow: 'hidden' }}
      onMouseMove={handleMouseMove}
    >
      <img
        alt={alt}
        style={{
          position: 'relative',
          left: `${-imgPosition.x * zoom}%`,
          top: `${-imgPosition.y * zoom}%`,
          transform: `scale(${zoom})`,
          transition: 'transform .2s',
            ...style,
        }}
        src={src}
      />
    </div>
  );
};

export default ZoomImage;
