import NavigationOutlinedIcon from '@material-ui/icons/NavigationOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import FormatUnderlinedOutlinedIcon from '@material-ui/icons/FormatUnderlinedOutlined';
import GamesOutlinedIcon from '@material-ui/icons/GamesOutlined';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import CallToActionOutlinedIcon from '@material-ui/icons/CallToActionOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import FilterVintageOutlinedIcon from '@material-ui/icons/FilterVintageOutlined';
import FormatColorTextOutlinedIcon from '@material-ui/icons/FormatColorTextOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import ChromeReaderModeOutlinedIcon from '@material-ui/icons/ChromeReaderModeOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import EditAttributesOutlinedIcon from '@material-ui/icons/EditAttributesOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import TranslateIcon from '@material-ui/icons/Translate';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import EditIcon from '@material-ui/icons/Edit';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TollIcon from '@material-ui/icons/Toll';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

const icons = {
    NavigationOutlinedIcon: NavigationOutlinedIcon,
    HomeOutlinedIcon: HomeOutlinedIcon,
    FormatUnderlinedOutlinedIcon: FormatUnderlinedOutlinedIcon,
    GamesOutlinedIcon: GamesOutlinedIcon,
    LoyaltyOutlinedIcon: LoyaltyOutlinedIcon,
    ForumOutlinedIcon: ForumOutlinedIcon,
    DescriptionOutlinedIcon: DescriptionOutlinedIcon,
    GridOnOutlinedIcon: GridOnOutlinedIcon,
    TableChartOutlinedIcon: TableChartOutlinedIcon,
    AccountTreeOutlinedIcon: AccountTreeOutlinedIcon,
    CallToActionOutlinedIcon: CallToActionOutlinedIcon,
    NotificationsNoneOutlinedIcon: NotificationsNoneOutlinedIcon,
    AppsOutlinedIcon: AppsOutlinedIcon,
    SmsOutlinedIcon: SmsOutlinedIcon,
    FilterVintageOutlinedIcon: FilterVintageOutlinedIcon,
    FormatColorTextOutlinedIcon: FormatColorTextOutlinedIcon,
    ContactSupportOutlinedIcon: ContactSupportOutlinedIcon,
    LayersOutlinedIcon: LayersOutlinedIcon,
    BlockOutlinedIcon: BlockOutlinedIcon,
    ChromeReaderModeOutlinedIcon: ChromeReaderModeOutlinedIcon,
    FiberManualRecordOutlinedIcon: FiberManualRecordOutlinedIcon,
    EditAttributesOutlinedIcon: EditAttributesOutlinedIcon,
    FaceOutlinedIcon: FaceOutlinedIcon,
    RadioButtonUncheckedOutlinedIcon: RadioButtonUncheckedOutlinedIcon,
    QuestionAnswerOutlinedIcon: QuestionAnswerOutlinedIcon,
    AccountCircleOutlinedIcon: AccountCircleOutlinedIcon,
    ShoppingCartOutlinedIcon: ShoppingCartOutlinedIcon,
    MailOutlineRoundedIcon: MailOutlineRoundedIcon,
    SpeakerNotesOutlinedIcon: SpeakerNotesOutlinedIcon,
    CardGiftcardOutlinedIcon: CardGiftcardOutlinedIcon,
    ErrorOutlineRoundedIcon: ErrorOutlineRoundedIcon,
    HourglassEmptyRoundedIcon: HourglassEmptyRoundedIcon,
    MonetizationOnOutlinedIcon: MonetizationOnOutlinedIcon,
    AssignmentIndOutlinedIcon: AssignmentIndOutlinedIcon,
    SecurityOutlinedIcon: SecurityOutlinedIcon,
    HelpOutlineOutlinedIcon: HelpOutlineOutlinedIcon,
    TranslateIcon: TranslateIcon,
    FormatListNumberedRtlIcon: FormatListNumberedRtlIcon,
    EventNoteIcon: EventNoteIcon,
    PersonIcon: PersonIcon,
    GroupIcon: GroupIcon,
    EditIcon: EditIcon,
    CropOriginalIcon: CropOriginalIcon,
    AssignmentTurnedInOutlinedIcon: AssignmentTurnedInOutlinedIcon,
    ReceiptIcon: ReceiptIcon,
    TollIcon: TollIcon,
    PeopleOutlineIcon: PeopleOutlineIcon,
};

export default {
    items: [
        {
            id: 'navigation',
            // title: 'Materially',
            // caption: 'Dashboard & Widgets',
            type: 'group',
            icon: icons['NavigationOutlinedIcon'],
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    icon: icons['HomeOutlinedIcon'],
                    url: '/dashboard/default',
                },
                {
                    id: 'heroes',
                    title: 'Heroes',
                    type: 'item',
                    url: '/heroes/list',
                    icon: icons['PersonIcon'],
                },
                {
                    id: 'teams',
                    title: 'Teams',
                    icon: icons['GroupIcon'],
                    type: 'item',
                    url: '/teams/list',
                },
                // {
                //     id: 'credits',
                //     title: 'Credits',
                //     type: 'item',
                //     url: '/credits/list',
                //     icon: icons['TollIcon'],
                // },
                {
                    id: 'editors',
                    title: 'Editors',
                    icon: icons['EditIcon'],
                    type: 'item',
                    url: '/editors/list',
                },
                {
                    id: 'editor-invoice-report',
                    title: 'Editor Invoice Report',
                    icon: icons['ReceiptIcon'],
                    type: 'item',
                    url: '/editors/invoice-report',
                },
                {
                    id: 'backgrounds',
                    title: 'Backgrounds',
                    icon: icons['CropOriginalIcon'],
                    type: 'item',
                    url: '/backgrounds/list',
                },
                {
                    id: 'production',
                    title: 'Production Manager',
                    icon: icons['AssignmentTurnedInOutlinedIcon'],
                    type: 'collapse',
                    children: [
                        {
                            id: 'production-assign',
                            title: 'Assign To Editor',
                            type: 'item',
                            url: '/production/assign',
                        },
                        {
                            id: 'production-deliver',
                            title: 'QA & Deliver',
                            type: 'item',
                            url: '/production/deliver',
                        },
                    ]
                
                },
                {
                    id: 'affiliate',
                    title: 'Affiliate',
                    icon: icons['CallToActionOutlinedIcon'],
                    type: 'collapse',
                    children: [
                        {
                            id: 'affiliates',
                            title: 'Affiliates',
                            type: 'item',
                            url: '/affiliates/list',
                        },
                        {
                            id: 'affiliate-referrals',
                            title: 'Referrals',
                            type: 'item',
                            url: '/affiliates/referrals',
                        },
                    ]
                
                },
                {
                    id: 'leads',
                    title: 'Leads',
                    icon: icons['PeopleOutlineIcon'],
                    type: 'item',
                    url: '/leads/list',
                },
            ],
        },
        
    ],
};
