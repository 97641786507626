import moment from "moment";

function parseAndFormatDateTime(dateTime, stripTime = false) {
    if (!dateTime) {
        return "";
    }

    var date = moment.utc(dateTime);

    
    if (date.isValid()) {
        let etTimezoneOffset = -4;
        if (moment().isDST()) {
            etTimezoneOffset = -4;
        }

        if (stripTime) {
            return date.utcOffset(etTimezoneOffset).format("MM/DD/YYYY");
        }
        return date.utcOffset(etTimezoneOffset).format("MM/DD/YYYY h:mm A") + " ET";
    }

    return "";
}

export {
    parseAndFormatDateTime
}
