import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import axios from 'axios';
import { wordpressBaseUrl } from 'settings';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { CircularProgress } from '@material-ui/core';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
   //  '&:focus': {
   //    backgroundColor: theme.palette.primary.main,
   //    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
   //      color: theme.palette.common.white,
   //    }
   //  },
    '&.active':{
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      }
    }
  },
}))(MenuItem);

export default function ReviewerDropdown() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [active, setActive] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const ExpEditors =[
    {name:"Offline", slack_id: "", email:"offline"},
    {name:"Emily", slack_id: "U0381KML8JJ", email:"emily@headshots.com"},
    {name:"Joaquin", slack_id: "U06TWC43L3B", email:"joaquin@headshots.com"},
    {name:"Fred", email:"fred@headshots.com"},
    {name:"Nenad", email:"nenad@headshots.com"},
    {name:"Channelle", slack_id: "U053DHJ6DN2", email:"channelle@headshots.com"},
    {name:"Christy", slack_id: "U038B139V0S", email:"christy@headshots.com"},
  ];
 
   useEffect(()=>{
    getExpeditedQAEditor();
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectExpeditedQA = (editor) => {
   console.log('editor', editor)
   setActive(editor)
  }

  const saveExpeditedQAEditor = async() => {
   setLoading(true)
    let selectedEditor = ExpEditors.find(edt=>edt.name === active)
   //  console.log('selectedEditor', selectedEditor)
    const res = await axios.post(`${wordpressBaseUrl}/wp-json/hh-api/add-expedited-qa-editor`, {name: selectedEditor.name, email: selectedEditor.email, reviewer: 1});
   //  console.log("res", res);
    if (res.data.success){
      setLoading(false)
      handleClose();
    }
  }

  const getExpeditedQAEditor = async() => {
   //  let selectedEditor = ExpEditors.find(edt=>edt.name === active)
    // console.log('selectedEditor', selectedEditor)
    const res = await axios.get(`${wordpressBaseUrl}/wp-json/hh-api/get-expedited-qa-editor?reviewer=true`);
    if(res.data.data){
      setActive(res.data.data.name)
    }
  }

  return (
    <div>
      <span
        onClick={handleClick}
        style={{cursor: "pointer", padding: "8px 5px 8px 14px", display: "flex", justifyContent: "space-between", alignItems: "center"}}
      >
        <span>QA & Deliver</span>{active ? <>: <b>{active}</b></> : ''} <ArrowDropDownIcon />
      </span>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {ExpEditors.map(edt=>(<StyledMenuItem className={active === edt.name ? 'active' : ''} onClick={()=>selectExpeditedQA(edt.name)}>
          <ListItemIcon>
          {active === edt.name ? <CheckIcon fontSize="small"/> : ''}
          </ListItemIcon>
          <ListItemText primary={edt.name} />
        </StyledMenuItem>))}
        
        <StyledMenuItem style={{display: "flex", justifyContent: "center"}} onClick={()=>saveExpeditedQAEditor()}>
        <Button variant="contained" color="primary">Save {loading ? <CircularProgress style={{color: "#ffffff", marginLeft:"10px"}} size={20} />: ""}</Button>
      </StyledMenuItem>
      </StyledMenu>
      
    </div>
  );
}
