const imageResizeEndpoint = "https://d24j8drerh99og.cloudfront.net/hero";

function getCdnImagePath(imagePath, width = null, height = null) {
    if (imagePath === null) return null;

    const imageName = getImageNameFromUrl(imagePath);
    const urlBase = `${imageResizeEndpoint}/${imageName}`;

    let urlParams = "";
    if (width !== null || height !== null) {
      const params = new URLSearchParams();
      if (width !== null) params.append("w", width);
      if (height !== null) params.append("h", height);
      urlParams = `?${params.toString()}`;
    }

    const cdnImagePath = urlBase + urlParams;
    return cdnImagePath;
}

function getImageNameFromUrl(imageUrl) {
  if (!imageUrl) return null;
    const path = new URL(imageUrl).pathname;
    const segments = path.split("/");
    const lastSegment = segments.pop() || segments.pop(); // handle trailing slash

    return lastSegment;
}

export {getCdnImagePath, getImageNameFromUrl}