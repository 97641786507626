import { Accordion, AccordionDetails, AccordionSummary, IconButton, makeStyles, Menu, MenuItem, Paper, Table, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react'
import NotificationModal from './NotificationModal';
import Axios from 'axios';
import { wordpressBaseUrl } from 'settings';
import { TableBody } from 'mui-datatables';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
   heading: {
       fontSize: theme.typography.pxToRem(15),
       fontWeight: theme.typography.fontWeightBold,
   },
   table: {
    minWidth: 650
   }
}));

function RowActionMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const open = Boolean(anchorEl);
  const {notification, setEditNotification, afterSave, closeMore} = props;

  useEffect(()=>{
    if(closeMore){
      handleClose()
    }
  }, [closeMore])

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const editNote = () => {
    setEditNotification(notification);
    console.log('notification', notification);
  }

  const deleteNote = async() => {
    console.log('notification', notification)
    if (window.confirm('Are you sure!')) {
      const url = `${wordpressBaseUrl}/wp-json/hh-api/delete-notification`;
      const input = {id: notification.id};
      const reponse = await Axios.post(url, input);
      console.log("update team response", reponse.data);
      if (reponse.data.success) {
        afterSave();
        handleClose();
      }
    }
  }

  return (
      <div>
          <IconButton
              aria-label="more"
              id="long-button"
              style={{paddingBottom: "0px"}}
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
          >
              <MoreVertIcon />
          </IconButton>
          <Menu
              id="long-menu"
              MenuListProps={{
                  'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                  style: {
                      width: '22ch',
                  },
              }}
          >
              <MenuItem key={2} selected={false} onClick={editNote}>
                  <span>Edit</span>
              </MenuItem>
              <MenuItem key={2} selected={false} onClick={deleteNote}>
                  <span>Delete</span>
              </MenuItem>
          </Menu>
      </div>
  );
}

const ProductionUpdates = () => {
   const classes = useStyles();
   const [showAddModal, setShowAddModal] = useState(false);
   const [notifications, setNotifications] = useState();
   const [editNotification, setEditNotification] = useState();
   const [expanded, setExpanded] = useState(false);
   const [closeMore, setCloseMore] = useState(false);

   useEffect(()=>{
      getNotifications();
      getUsermeta();
   }, []);

   useEffect(()=>{
    if (editNotification) {
      setCloseMore(false);
      setShowAddModal(true)
    }
   }, [editNotification])

   const getNotifications = async() => {
      try {
        const response = await Axios.get(`${wordpressBaseUrl}/wp-json/hh-api/get-notifications?type=production-update`);
        // console.log('response', response.data.data);
        if (response?.data?.success) {
          setNotifications(response.data.data)
        }
      } catch(error) {
        console.log('error', error)
        // setNotifications([])
      }
   }

   const getUsermeta = async() => {
      try {
        const response = await Axios.get(`${wordpressBaseUrl}/wp-json/hh-api/user/usermeta?meta_key=production_updates`);
        console.log('response', response)
        if (parseInt(response.data.user_meta) === 1){
          setExpanded(true)
        }
      } catch(error) {
        console.log('error', error)
      }
   }

   const updateUserMeta = async(meta_value) => {
      try {
        const response = await Axios.post(`${wordpressBaseUrl}/wp-json/hh-api/user/usermeta/update`, {meta_key: 'production_updates', meta_value: meta_value});
        console.log('response', response)
        
      } catch(error) {
        console.log('error', error)
      }
   }

   const afterSave = () => {
    getNotifications();
    setCloseMore(true);
    setShowAddModal(false)
   }

   const handleShowModal = () => {
      //setExpanded(false);
      setEditNotification();
      setShowAddModal(true)
   }

   const handleExpanded = () => {
    let meta_value = expanded ? 0 : 1;
    setExpanded(!expanded);
    updateUserMeta(meta_value)
   }
   if (!notifications) return <></>;
  return (
    <div style={{marginBottom: "20px"}}>
      <Accordion expanded={expanded}>
    <AccordionSummary
        expandIcon={<ExpandMore onClick={()=>handleExpanded()} />}
        aria-controls="panel1a-content"
        id="comments"
    >
        <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}><Typography className={classes.heading}>PRODUCTION UPDATES</Typography>
        <AddIcon style={{color: "#ffffff", background: "#3366FF"}} onClick={handleShowModal} /></div>
    </AccordionSummary>
    <AccordionDetails>
      <div style={{width: "100%", borderTop: "1px solid #eee", maxHeight: "20rem", overflowY: "scroll"}}>
            {notifications?.map((row) => (
              <div style={{display: 'flex', padding: "10px 0", alignItems: "center", justifyContent: "space-between", width: '100%', borderBottom: "1px solid #eee"}}>
                <div>
                  {row.message}
                </div>
                <RowActionMenu closeMore={closeMore} setEditNotification={setEditNotification} afterSave={afterSave} notification={row} />
                </div>
            ))}
          </div>
    </AccordionDetails>
</Accordion>
<NotificationModal open={showAddModal} afterSave={afterSave} setEditNotification={setEditNotification} editNotification={editNotification} onClose={()=>{setShowAddModal(false);setEditNotification()}} />
</div>
  )
}

export default ProductionUpdates