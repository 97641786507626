import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ROLE_ADMIN_SITE_CUSTOMER_SUPPORT, ROLE_EDITOR } from 'utils/constants';
const isEditorUpload = (pathname) => {
    const pathArr = pathname.split('/');
    if (pathArr[1] === 'editor' && pathArr[3] === 'uploads') {
        return true;
    }
    return false;
}
const AuthGuard = ({ children }) => {
    const { isLoggedIn, user } = useAuth();
    const {pathname} = useLocation();
    // console.log('user', user, pathname)
    if (!isLoggedIn) {
        return <Redirect to="/login" />;
    }
    const prod_routes = ['/production/assign', '/production/deliver'];
    if (user?.roles?.includes(ROLE_ADMIN_SITE_CUSTOMER_SUPPORT) && prod_routes.includes(pathname)) {
        return <Redirect to="/dashboard/default" />;
    }
    if (user?.roles?.includes(ROLE_EDITOR) && !pathname.includes('/editor/requests/') && !isEditorUpload(pathname)) {
        // console.log('on role editor', user)
        return <Redirect to={`/editor/requests/${user.editor.public_id}`} />;
    }
    return children;
};

export default AuthGuard;
