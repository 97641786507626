export const wordpressBaseUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL
    : window.location.hostname === "admin.headshots.com" 
        ? "https://my.headshots.com"
        : "https://uat-my.headshots.com";

export const resubmitRequestOptions = {
        "background": {
            name: "Background",
            options: [
                {
                    key: "insufficient_separation_head",
                    reason: "Insufficient separation between the subject's head and background",
                    why: "The area behind your head is cluttered, resulting in an unclear separation between your head and the background.",
                    why_fr: "La zone derrière votre tête est encombrée, ce qui crée une séparation peu nette entre votre tête et l'arrière-plan.",
                    how: "Move to an area with a more neutral background, such as a plain indoor wall. If no such space is available, consider draping a sheet or positioning yourself to avoid clutter directly behind your head.",
                    how_fr: "Déplacez-vous dans une zone avec un arrière-plan plus neutre, comme un mur intérieur uni. Si un tel espace n'est pas disponible, envisagez d'étendre un drap ou de vous positionner de manière à éviter les éléments encombrants directement derrière votre tête.",
                    exampleImagePath: "/images/resubmit/2.jpg"
                },
                {
                    key: "insufficient_separation_torso",
                    reason: "Insufficient separation between the subject's torso and background",
                    why: "Your body cannot be easily distinguished from the background.",
                    why_fr: "Votre corps ne peut pas être facilement distingué de l'arrière-plan.",
                    how: "Move to an area where there's enough contrast between your outfit and the background.",
                    how_fr: "Déplacez-vous dans une zone où il y a suffisamment de contraste entre votre tenue vestimentaire et l'arrière-plan.",
                    exampleImagePath: "/images/resubmit/5.jpg"
                },
                {
                    key: "color_too_similar_hair",
                    reason: "Background color too similar to hair color",
                    why: "Your hair color is too similar to the background, making it hard to distinguish.",
                    why_fr: "La couleur de vos cheveux est trop similaire à l'arrière-plan, ce qui rend difficile la distinction.",
                    how: "Move to an area with a background that is different from your hair color. It may help to hang a towel or sheet behind you.",
                    how_fr: "Déplacez-vous dans une zone avec un arrière-plan qui est différent de la couleur de vos cheveux. Il peut être utile d'accrocher une serviette ou un drap derrière vous.",
                    exampleImagePath: "/images/resubmit/4.jpg"
                },
                {
                    key: "color_too_similar_clothing",
                    reason: "Background color too similar to clothing color",
                    why: "Your clothing color is too similar to the background, making it hard to distinguish.",
                    how: "Move to an area with a backdrop that differs in color from your clothing. It may help to hang a towel or sheet behind you.",
                    exampleImagePath: null
                },
            ],
        },
        "clothing": {
            name: "Clothing",
            options: [
                {
                    key: "glasses_glare",
                    reason: "Strong glare on glasses",
                    why: "There is a strong glare on your glasses, obscuring your eyes.",
                    why_fr: "Il y a un fort éblouissement sur vos lunettes, ce qui obscurcit vos yeux.",
                    how: "Tilt your head or adjust the camera angle slightly to reduce the glare on your glasses.",
                    how_fr: "Inclinez légèrement la tête ou ajustez légèrement l'angle de la caméra pour réduire l'éblouissement sur vos lunettes.",
                    exampleImagePath: "/images/resubmit/7.jpg"
                },
            ],
        },
        "composition": {
            name: "Composition",
            options: [
                {
                    key: "subject_too_close_to_camera",
                    reason: "Subject too close to the camera, obscuring parts of their body",
                    why: "You are standing too close to the camera, cutting too much of your torso and/or limbs out of the camera frame.",
                    why_fr: "Vous êtes trop proche de l'appareil photo, ce qui coupe une grande partie de votre buste et/ou de vos membres du cadre de l'appareil photo.",
                    how: "Stand a bit further from the camera to ensure your torso is fully in frame, with minimal space above your head, and your arms visible. Position the phone horizontally when taking the photo.",
                    how_fr: "Éloignez-vous un peu de l'appareil photo pour vous assurer que votre buste est entièrement dans le cadre, avec un espace minimal au-dessus de votre tête et vos bras visibles. Positionnez le téléphone horizontalement lors de la prise de la photo.",
                    exampleImagePath: "/images/resubmit/9.jpg"
                },
                {
                    key: "subject_too_far_from_camera",
                    reason: "Subject is standing too far from the camera making them pixelated",
                    why: "Standing too far from the camera has pixelated your image when zoomed in for editing.",
                    why_fr: "Être trop éloigné de l'appareil photo a pixelisé votre image lorsqu'elle est agrandie pour l'édition.",
                    how: "Stand closer to the camera, making sure your waist is at the bottom of the frame with minimal space above your head.",
                    how_fr: "Rapprochez-vous de l'appareil photo, en veillant à ce que votre taille soit en bas du cadre avec un espace minimal au-dessus de votre tête.",
                    exampleImagePath: "/images/resubmit/11.jpg"
                },
                {
                    key: "torso_obscured",
                    reason: "Selfie taken, obscuring parts of the torso and resulting in unflattering poses",
                    why: "Taking a selfie removed a significant portion of your upper body out of the frame.",
                    why_fr: "Prendre un selfie a retiré une partie importante de la partie supérieure de votre corps du cadre.",
                    how: "If you are taking your photo by yourself: Prop up your camera near a window with ample natural light, use the camera timer, and stand back to ensure your torso and arms are in the frame. Alternatively, ask someone to take your photo for you.",
                    how_fr: "Si vous prenez votre photo vous-même : Placez votre appareil photo près d'une fenêtre avec beaucoup de lumière naturelle, utilisez le minuteur de l'appareil photo et reculez pour vous assurer que votre torse et vos bras sont dans le cadre. Vous pouvez également demander à quelqu'un de prendre votre photo pour vous.",
                    exampleImagePath: "/images/resubmit/10.jpg"
                },
                {
                    key: "non_standard_pose_approval_required",
                    reason: "Non-standard headshot pose (approval required)",
                    why: "Your pose doesn't match our standard headshot pose, causing inconsistency with the rest of your team.",
                    why_fr: "Votre pose ne correspond pas à notre pose de portrait standard, ce qui crée une incohérence avec le reste de votre équipe.",
                    how: "Please retake your photo following our posing guidelines. If you believe our feedback is incorrect, respond to this email detailing why your pose aligns with the rest of your team. Also provide confirmation of approval from your team leader.",
                    how_fr: "Veuillez reprendre votre photo en suivant nos directives de pose. Si vous pensez que nos commentaires sont incorrects, répondez à cet e-mail en expliquant pourquoi votre pose correspond au reste de votre équipe. Fournissez également une confirmation d'approbation de la part de votre chef d'équipe.",
                    exampleImagePath: "/images/resubmit/12.jpg"
                },
                {
                    key: "non_standard_pose_unacceptable",
                    reason: "Non-standard headshot pose (unacceptable)",
                    why: "Your pose doesn't match our standard headshot pose, making it not possible to edit.",
                    why_fr: "Votre pose ne correspond pas à notre pose de portrait standard, ce qui rend l'édition impossible.",
                    how: "Please retake your photo following our posing guidelines. When retaking your photo, ensure both arms and your torso from the waist up are visible.",
                    how_fr: "Veuillez reprendre votre photo en suivant nos directives de pose. Lorsque vous reprenez votre photo, assurez-vous que les deux bras et votre torse à partir de la taille sont visibles.",
                    exampleImagePath: "/images/resubmit/13.jpg"
                },
                {
                    key: "camera_angle_too_high",
                    reason: "Camera angle too high above the subject",
                    why: "The camera angle is too high, distorting the photo perspective and making it hard to determine body proportions.",
                    why_fr: "L'angle de la caméra est trop élevé, ce qui déforme la perspective de la photo et rend difficile la détermination des proportions du corps.",
                    how: "Position the camera at eye level and keep it horizontal when taking the photo.",
                    how_fr: "Positionnez la caméra à hauteur des yeux et gardez-la horizontale lors de la prise de la photo.",
                    exampleImagePath: "/images/resubmit/14.jpg"
                },
                {
                    key: "camera_angle_too_low",
                    reason: "Camera angle too low ",
                    why: "The camera angle is too low, distorting perspective and making it hard to determine body proportions.",
                    why_fr: "L'angle de la caméra est trop bas, ce qui déforme la perspective et rend difficile la détermination des proportions du corps.",
                    how: "Position the camera at eye level and keep it horizontal when taking the photo.",
                    how_fr: "Positionnez la caméra à hauteur des yeux et gardez-la horizontale lors de la prise de la photo.",
                    exampleImagePath: "/images/resubmit/15.jpg"
                },
                {
                    key: "subject_not_looking_at_camera",
                    reason: "Not looking directly at the camera lens",
                    why: "Your eyes are not looking directly at the camera, which may appear unnatural.",
                    why_fr: "Vos yeux ne regardent pas directement l'objectif de la caméra, ce qui peut sembler peu naturel.",
                    how: "Retake your photo looking directly at the camera lens. ",
                    how_fr: "Refaites votre photo en fixant votre regard sur la lentille de la caméra.",
                    exampleImagePath: "/images/resubmit/16.jpg"
                },
            ],
        },
        "lighting": {
            name: "Lighting",
            options: [
                {
                    key: "sunlight_casting_shadows_on_face",
                    reason: "Direct sunlight casting harsh shadows on the face",
                    why: "The direct sunlight is causing harsh shadows around your features. ",
                    why_fr: "La lumière directe du soleil crée des ombres dures autour de vos traits.",
                    how: "We recommend taking your photo indoors in front a window with plenty of natural lighting to allow the lighting to fall evenly over your face. ",
                    how_fr: "Nous recommandons de prendre votre photo à l'intérieur devant une fenêtre avec beaucoup de lumière naturelle pour permettre à la lumière de tomber uniformément sur votre visage.",
                    exampleImagePath: "/images/resubmit/18.jpg"
                },
                {
                    key: "sunlight_causing_skin_overexposure",
                    reason: "Direct sunlight causing skin overexposure",
                    why: "The lighting on your skin is overexposed, making it difficult to recover any detail in the photo.",
                    why_fr: "L'éclairage sur votre peau est surexposé, rendant difficile la récupération des détails de la photo.",
                    how: "We recommend taking your photo indoors in front a window with plenty of natural lighting to allow the lighting to fall evenly over your face.",
                    how_fr: "Nous recommandons de prendre votre photo à l'intérieur devant une fenêtre avec beaucoup de lumière naturelle pour permettre à la lumière de tomber uniformément sur votre visage.",
                    exampleImagePath: null
                },
                {
                    key: "uneven_lighting_shadows_on_one_side_of_face",
                    reason: "Uneven lighting with one side of the face in shadow",
                    why: "One side of the face is unevenly lit resulting in a dramatic contrast and loss of detail on one side of your face.",
                    why_fr: "Un côté du visage est éclairé de manière inégale, créant un contraste dramatique et une perte de détails d'un côté de votre visage.",
                    how: "Turn to face directly towards the window or other natural light source to allow the natural light to fall evenly over the front of your face. ",
                    how_fr: "Tournez-vous pour faire face directement à la fenêtre ou à une autre source de lumière naturelle pour permettre à la lumière naturelle de tomber uniformément sur l'avant de votre visage.",
                    exampleImagePath: "/images/resubmit/20.jpg"
                },
                {
                    key: "overhead_lighting_causing_orange_tone",
                    reason: "Overhead lighting causing orange tone",
                    why: "The overhead lighting is causing an orange tone in your image.",
                    why_fr: "L'éclairage en hauteur crée une tonalité orange dans votre image.",
                    how: "Turn off all indoor lights and stand near a window with ample natural light for even lighting on your face.",
                    how_fr: "Éteignez toutes les lumières intérieures et tenez-vous près d'une fenêtre avec beaucoup de lumière naturelle pour obtenir un éclairage uniforme sur votre visage.",
                    exampleImagePath: "/images/resubmit/21.jpg"
                },
                {
                    key: "over_light_casting_shadows_on_face",
                    reason: "Overhead lighting casting harsh facial shadows",
                    why: "Overhead lighting is casting harsh shadows on your face.",
                    why_fr: "L'éclairage en hauteur projette des ombres dures sur votre visage.",
                    how: "Turn off all indoor lights and stand near a window with ample natural light for even lighting on your face.",
                    how_fr: "Éteignez toutes les lumières intérieures et tenez-vous près d'une fenêtre avec beaucoup de lumière naturelle pour obtenir un éclairage uniforme sur votre visage.",
                    exampleImagePath: "/images/resubmit/22.jpg"
                },
                {
                    key: "subject_poorly_lit_too_dark",
                    reason: "Subject poorly lit (low lighting)",
                    why: "Your photo is dark/underexposed due to insufficient natural light. ",
                    why_fr: "Votre photo est sombre/sous-exposée en raison d'un manque de lumière naturelle.",
                    how: "Stand in a well-lit room with ample natural light. Choose a larger window, or move closer to the window, to increase the amount of light.",
                    how_fr: "Tenez-vous dans une pièce bien éclairée avec beaucoup de lumière naturelle. Choisissez une fenêtre plus grande ou rapprochez-vous de la fenêtre pour augmenter la quantité de lumière.",
                    exampleImagePath: "/images/resubmit/23.jpg"
                },
                {
                    key: "subject_poorly_lit_excessive_image_noise",
                    reason: "Subject poorly lit (little to no lighting)",
                    why: "There is a lot of image noise (graininess) in your photo due to insufficient natural light. ",
                    why_fr: "Il y a beaucoup de bruit d'image (granulation) dans votre photo en raison d'un manque de lumière naturelle.",
                    how: "Stand in a well-lit room with ample natural light. Choose a larger window, or move closer to the window, to increase the amount of light.",
                    how_fr: "Tenez-vous dans une pièce bien éclairée avec beaucoup de lumière naturelle. Choisissez une fenêtre plus grande ou rapprochez-vous de la fenêtre pour augmenter la quantité de lumière.",
                    exampleImagePath: "/images/resubmit/24.jpg"
                },
                {
                    key: "subject_backlit",
                    reason: "Subject is backlit",
                    why: "The light source is behind you, making you appear backlit and underexposed.",
                    why_fr: "La source lumineuse est derrière vous, ce qui vous donne un aspect rétroéclairé et sous-exposé.",
                    how: "Turn your body to face directly towards the window to allow the natural lighting to fall evenly over your face. Do not stand with a light source behind your back.",
                    how_fr: "Tournez votre corps pour faire face directement à la fenêtre pour permettre à l'éclairage naturel de tomber uniformément sur votre visage. Ne vous tenez pas avec une source de lumière derrière votre dos.",
                    exampleImagePath: "/images/resubmit/25.jpg"
                },
                {
                    key: "flash_causing_harsh_light_and_redeye",
                    reason: "Flash used, resulting in harsh lighting and red-eye effect",
                    why: "The flash is causing harsh shadows on your face and a red-eye effect in your eyes.",
                    why_fr: "Le flash crée des ombres dures sur votre visage et un effet yeux rouges dans vos yeux.",
                    how: "Turn your body to face directly towards the window to allow the natural lighting to fall evenly over your face. Do not stand with a light source behind your back.",
                    how_fr: "Tournez votre corps pour faire face directement à la fenêtre pour permettre à l'éclairage naturel de tomber uniformément sur votre visage. Ne vous tenez pas avec une source de lumière derrière votre dos.",
                    exampleImagePath: "/images/resubmit/26.jpg"
                },
            ],
        },
        "quality": {
            name: "Quality",
            options: [
                {
                    key: "excessive_blur_from_portrait_mode",
                    reason: "Excessive blurring from portrait mode",
                    why: "Portrait mode is causing too much of a blur around your outline, making it difficult to clearly separate you from the background. ",
                    why_fr: "Le mode portrait crée un flou excessif autour de votre contour, ce qui rend difficile de vous distinguer clairement de l'arrière-plan.",
                    how: "Turn off portrait mode and use regular photo mode when you retake your photo. ",
                    how_fr: "Désactivez le mode portrait et utilisez le mode photo normal lorsque vous reprenez votre photo.",
                    exampleImagePath: "/images/resubmit/27a.jpg"
                },
                {
                    key: "low_resolution",
                    reason: "Low image resolution",
                    why: "Your file resolution is low, causing quality issues.",
                    why_fr: "La résolution de votre fichier est basse, ce qui entraîne des problèmes de qualité.",
                    how: "For Androids, please shoot in \"Photo\" mode then click the settings icon to change your camera's resolution to 3:4 or a higher resolution like 64MP. For iPhones, checkmark \"High Efficiency\" and toggle Smart HDR in your camera settings.",
                    how_fr: "Pour les appareils Android, veuillez prendre des photos en mode \"Photo\", puis cliquez sur l'icône des paramètres pour changer la résolution de votre appareil photo en 3:4 ou une résolution plus élevée, comme 64 MP. Pour les iPhones, cochez la case \"Haute efficacité\" et activez le Smart HDR dans les paramètres de votre appareil photo.",
                    exampleImagePath: "/images/resubmit/28.jpg"
                },
                {
                    key: "subject_blurry_need_camera_focus",
                    reason: "Subject appears blurry; camera focus needed",
                    why: "Your face is out of focus in the photo. ",
                    why_fr: "Votre visage est flou dans la photo.",
                    how: "Tap the screen to focus the camera lens on your face. Keep the camera steady when taking the photo.",
                    how_fr: "Appuyez sur l'écran pour faire la mise au point de l'objectif de l'appareil photo sur votre visage. Maintenez l'appareil photo stable lors de la prise de la photo.",
                    exampleImagePath: "/images/resubmit/29.jpg"
                },
                {
                    key: "image_grainy_using_front_facing_camera",
                    reason: "Image grainy due to front-facing camera use",
                    why: "It appears that you're using the front-facing camera, which produces lower quality images.",
                    why_fr: "Il semble que vous utilisiez la caméra frontale, ce qui produit des images de moins bonne qualité.",
                    how: "Use the rear camera for higher resolution photos.",
                    how_fr: "Utilisez l'appareil photo arrière pour des photos de meilleure résolution.",
                    exampleImagePath: "/images/resubmit/30.jpg"
                },
                {
                    key: "photo_appears_filtered_or_edited",
                    reason: "Photo appears filtered or previously edited",
                    why: "It appears that your image has been previously filtered or edited, making it difficult for us to edit naturally. ",
                    why_fr: "Il semble que votre image ait été précédemment filtrée ou modifiée, ce qui rend difficile pour nous de réaliser une édition naturelle.",
                    how: "Avoid using effects or filters when taking photos to preserve image quality.",
                    how_fr: "Évitez d'utiliser des effets ou des filtres lors de la prise de photos pour préserver la qualité de l'image.",
                    exampleImagePath: "/images/resubmit/31.jpg"
                },
                {
                    key: "using_old_phone_model",
                    reason: "Older phone model",
                    why: "Your image's resolution is too low because it was taken with an older phone model.",
                    why_fr: "La résolution de votre image est trop basse car elle a été prise avec un modèle de téléphone plus ancien.",
                    how: "Use a phone model from 2014 or newer. If you don't have one, consider borrowing from a friend or colleague. If that's not an option, use the highest settings on your current phone and ensure there is good lighting.",
                    how_fr: "Utilisez un modèle de téléphone datant de 2014 ou plus récent. Si vous n'en avez pas, envisagez d'en emprunter un à un ami ou à un collègue. Si ce n'est pas possible, utilisez les paramètres les plus élevés sur votre téléphone actuel et assurez-vous qu'il y a une bonne lumière.",
                    exampleImagePath: "/images/resubmit/32.jpg"
                },
                {
                    key: "low_resolution_copy_of_original",
                    reason: "Low resolution copy of the original",
                    why: "Your photo seems to be a low-resolution copy of the original. It was possibly obtained from a photo of a photo, screenshot, thumbnail, or a down-scaled transfer.",
                    why_fr: "Votre photo semble être une copie à faible résolution de l'originale. Elle a peut-être été obtenue à partir d'une photo d'une photo, d'une capture d'écran, d'une vignette ou d'un transfert à échelle réduite.",
                    how: "Send the original image file directly from the source. Use the QR code for direct uploads from your camera roll or attach the file from your computer. Avoid texting, screenshotting, or taking pictures of the photo to prevent compression issues.",
                    how_fr: "Envoyez le fichier d'image d'origine directement à partir de la source. Utilisez le code QR pour télécharger directement depuis votre pellicule ou attachez le fichier depuis votre ordinateur. Évitez de faire des captures d'écran ou de prendre des photos de la photo pour éviter les problèmes de compression.",
                    exampleImagePath: "/images/resubmit/33a.jpg"
                }
            ],
        },
    };


export const resubmitRequestEmailTemplateCode = "headshot-resubmit-v2";
export const resubmitRequestEmailTemplateCode_fr = "headshot-resubmit-v2-fr";