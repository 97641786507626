import React, { useState, useEffect } from 'react';

import { IntlProvider } from 'react-intl';

import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { JWTProvider } from '../contexts/JWTContext';
import theme from './../themes';
import Routes from '../Routes';
import NavigationScroll from './NavigationScroll';
import Snackbar from '../component/Snackbar';

import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const App = () => {
    const customization = useSelector((state) => state.customization);
    const [messages, setMessages] = useState();


    return (
      <React.Fragment>
        <CssBaseline />
            <NavigationScroll>
                <StylesProvider jss={jss}>
                  <ThemeProvider theme={theme(customization)}>
                    <JWTProvider>
                        <Routes />
                        <Snackbar />
                    </JWTProvider>
                  </ThemeProvider>
                </StylesProvider>
            </NavigationScroll>
      </React.Fragment>
    );
};

export default App;
