import React, { useState } from "react";
import { Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

const ShowGuidelineMenu = (props) => {
   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);
   const handleClick = (event) => {
       setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
       setAnchorEl(null);
       props.handleClick()
   };

   return (
       <div>
           <IconButton
               aria-label="more"
               id="long-button"
               aria-controls={open ? 'long-menu' : undefined}
               aria-expanded={open ? 'true' : undefined}
               aria-haspopup="true"
               onClick={handleClick}
               style={{padding: 0}}
           >
               <Button style={{ marginLeft: 8}} variant="contained" color="primary"><VisibilityIcon /></Button>
           </IconButton>
           <Menu
               id="long-menu"
               MenuListProps={{
                   'aria-labelledby': 'long-button',
               }}
               anchorEl={anchorEl}
               open={open}
               onClose={handleClose}
               PaperProps={{
                   style: {
                       marginTop: "45px",
                       width: '22ch',
                   },
               }}
           >
               <MenuItem key={1} selected={false} onClick={()=>props.handleClick('standard') }>
                   <span>Guideline</span>
               </MenuItem>
               {props.addGuidelineImg && (<MenuItem key={2} selected={false} onClick={()=>props.handleClick('additional') }>
                   <span>Additional Guideline</span>
               </MenuItem>)}
           </Menu>
           
       </div>
   );
}

export default ShowGuidelineMenu;