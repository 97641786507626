import axios from 'axios';



// create axios intercepter that redirects to login page if 401 or 403 response is received
axios.interceptors.response.use(response => response, function (err) {
    console.log("error", err.response);
   if (err?.response?.status === 401 || err?.response?.status === 403 || err?.response?.status === 404) {
        if(localStorage.getItem('serviceToken')) {
            localStorage.removeItem('serviceToken');
            window.location.reload();
        }
        
       return;
   }
   return Promise.reject(err);
});


//export default axios;

const axiosServices = axios.create();

axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
