import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import { ROLE_EDITOR } from 'utils/constants';

const GuestGuard = ({ children }) => {
    const { isLoggedIn, user } = useAuth();

    if (isLoggedIn) {
        if (user?.roles?.includes(ROLE_EDITOR) && user?.editor) {
            return <Redirect to={`/editor/requests/${user.editor.public_id}`} />;
        } else {
            return <Redirect to="/dashboard/default" />;
        }
    }

    return children;
};

export default GuestGuard;
